import React from "react";
import * as F from "./countlessFunctions.style";

import Icon from "assets/images/new/30-Tage.svg";
import IconWithText from "./IconWithText";
import LightIconWithText from "./LightIconWithText";
import GreenIconWithText from "./GreenIconWithText";
import LightGreenIconWithText from "./LightGreenIconWithText";

import {
  SimpleFuntions,
  CommingSoonSimpleFunctions,
  GreenFunctions,
  CommingSoonGreenFunctions,
} from "./CountlessFunctionsData";

const CountlessFunctions = () => {
  const CountlessFunctions = [
    {
      img: Icon,
      title: "Kalorienübersicht",
      description:
        "Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im komplett verwahrlosten Taxi quer durch Bayern. Zwölf.",
    },
  ];

  return (
    <>
      <h6 className="ct-text-block uppercase font-medium color-dark text-xl">
        UNZÄHLIGE FUNKTIONEN:
      </h6>
      <div className="ct-div-block w-full mt-10 grid grid-cols-5 gap-10 items-stretch xl-grid-cols-4 md-grid-cols-3 sm-grid-cols-2">
        {SimpleFuntions.map((item) => (
          <IconWithText item={item} />
        ))}
        {CommingSoonSimpleFunctions.map((item) => (
          <LightIconWithText item={item} />
        ))}
        {GreenFunctions.map((item) => (
          <GreenIconWithText item={item} />
        ))}
        {CommingSoonGreenFunctions.map((item) => (
          <LightGreenIconWithText item={item} />
        ))}
      </div>
      <F.GridContainer className="mt-12">
        {/* {List.map((item) => (
          <F.FunctionCard>
            <span className="label">PRO</span>
            <img src={item.img} alt="icon" />
            <p className="title mb-0">{item.title}</p>
            <F.HoverContent className="hover-content">
              <p className="description mb-0">{item.description}</p>
              <div className="green-line" />
            </F.HoverContent>
          </F.FunctionCard>
        ))} */}
      </F.GridContainer>
    </>
  );
};

export default CountlessFunctions;
