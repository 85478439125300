import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from "./topbar.styles";
import { useHistory } from "react-router-dom";
import {getPercent, formatToGermanCurrency, getTargetValue} from "configs/utils";

import TranslationMessage from 'configs/translations/TranslationMessage';

// Images
import topbar from "assets/images/topbar.svg";
import topbarLogo from "assets/images/logo.svg";
import rightArrow from "assets/images/rightArrow.png";
import closeIcon from "assets/images/closeIcon.svg";

const Topbar = (props) => {
    const {prev, next, close, navTabs, profileDropdown} = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const authenticated = localStorage.getItem('trainer_token') || localStorage.getItem('token');
    // const registrationData = useSelector(state => state.auth.registrationData);

    return (
        <div>
           
        </div>
    )
}

export default Topbar;