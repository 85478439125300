import React from "react"
import * as F from "./startNutritionChange.style"

import StepCard from "components/general/stepCard/StepCard"
// Images
import IconFood from "assets/images/myCoaching/Icon-food.svg"

const StartNutritionChange = () => {
  return (
    <F.Wrap>
      <F.SectionTitle className='ct-text-block font-bold color-dark text-left text-4xl'>
        STARTE JETZT GEMEINSAM MIT MIR <br /> <b>DEINE ERNÄHRUNGSUMSTELLUNG!</b>
      </F.SectionTitle>
      <F.SectionTitleMobile className='ct-text-block font-bold color-dark text-left text-4xl'>
        STARTE JETZT GEMEINSAM MIT MIR <b>DEIN 12- WOCHEN COACHING!</b>
      </F.SectionTitleMobile>
      <F.CardsWrap>
        <StepCard
          title="Dein Ernährungsplan wird erstellt"
          description="In einem persönlichen Zoom-Call erstellen wir gemeinsam deinen eigenen Ernährungsplan und besprechen die Bedürfnisse deines Coachings."
          icon={IconFood}
        />
        <StepCard
          title="Deine Ernährungsumstellung beginnt"
          description="Ich richte deinen Ernährungsplan in der Foodiary App ein und schalte dir über 1700 gesunde und abwechslungsreiche Rezepte für deine tägliche Ernährungsplanung frei."
          icon={IconFood}
        />
        <StepCard
          title="Ich unterstütze dich persönlich auf deinem Weg!"
          description="Während deiner 3-monatigen Ernährungsumstellung wirst du persönlich von mir betreut und begleitet. Ich stehe als dein Partner an deiner Seite."
          icon={IconFood}
        />
      </F.CardsWrap>
    </F.Wrap>
  )
}

export default StartNutritionChange
