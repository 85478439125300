import React from "react";
import * as F from "./CoachingProFeatures.style";

import StepCard from "components/general/stepCard/StepCard";
// Images
import ProOne from "assets/images/new/before-after-coach.png";
import ProTwo from "assets/images/new/pro2.png";
import ProThree from "assets/images/new/pro3.png";

const CoachingProFeatures = () => {
  const Items = [
    {
      image: ProThree,
      title: "Gewichtsverlauf",
      description:
        "Erfolge feiern! In einer wöchentlichen Fortschrittsanalyse aktualisierst du dein Gewicht. Ich prüfe daraufhin mögliche Anpassungen.",
    },
    {
      image: ProTwo,
      title: "Körperdaten",
      description:
        "Das Gewicht ist nur ein Faktor, um den Erfolg zu messen. Trage deine Körpermaße ein, sodass du deinen Fortschritt überprüfen kannst.",
    },
    {
      image: ProOne,
      title: "Fortschritt",
      description:
        "Lade wöchentlich ein visuelles Update deines Fortschrittes hoch. Damit wird dein Erfolg auch sichtbar. Sei Stolz und teile diesen mit Freunden.",
    },
  ];

  return (
    <F.Wrap>
      <F.SectionTitle className="ct-headline foodiary-h2 color-dark text-4xl uppercase font-semibold">
        BEHALTE DEINE FORTSCHRITTE IM BLICK MIT DEN <br />
        <b>EXKLUSIVEN COACHING PRO FEATURES</b>
      </F.SectionTitle>
      <F.SectionTitleMobile className="ct-headline foodiary-h2 color-dark text-4xl uppercase font-semibold">
        BEHALTE DEINE FORTSCHRITTE IM BLICK MIT DEN
        <b>EXKLUSIVEN COACHING PRO FEATURES</b>
      </F.SectionTitleMobile>
      <F.CardsWrap>
        {Items.map((item) => (
          <F.ItemBox>
            <F.ItemCard>
              <img src={item?.image} alt="" />
            </F.ItemCard>
            <F.ItemTitle>{item.title}</F.ItemTitle>
            <F.ItemDescription className="ct-text-block color-paragraph text-base text-left">
              {item.description}
            </F.ItemDescription>
          </F.ItemBox>
        ))}
      </F.CardsWrap>
    </F.Wrap>
  );
};

export default CoachingProFeatures;
