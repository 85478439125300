import React from "react";
import * as F from "./countlessFunctions.style";

const GreenIconWithText = ({ item: { icon, title } }) => {
  return (
    <>
      <div
        id="div_block-396-18"
        class="ct-div-block bg-white shadow-md rounded-xl hover-float flex items-center overflow-hidden p-8 relative"
      >
        <div id="code_block-397-18" class="ct-code-block">
          <img className="function-icon" src={icon} alt={title} />
        </div>
        <F.ProBadge>PRO</F.ProBadge>
        <div
          id="text_block-399-18"
          class="ct-text-block text-lg text-center pt-5 color-dark"
        >
          {title}
        </div>
      </div>
    </>
  );
};

export default GreenIconWithText;
