import React from "react";
import Chip from "components/general/formComponents/chip/Chip";
import Button from "components/general/formComponents/button/Button";
import * as C from "./nutritionCoach.styled";
import * as G from "styles/General.Styles";
// Images
import PersonImage from "assets/images/Page3/Foodiary-Coaching-mobile.svg";

const NutritionCoach = ({ onPaymentModal }) => {
  return (
    <C.GeneralWrap>
      <C.TextContent>
        <Chip text="ERNÄHRUNGSPLAN" margin="0 0 3rem 0" />
        <C.Title className="ct-text-block font-bold color-dark text-left text-4xl">
          Ein eigener Foodiary-Coach ist der Schlüssel zum Erfolg!
        </C.Title>
        <C.Description className="ct-text-block color-paragraph text-base text-left">
          Konzentriere dich während deiner Ernährungsumstellung voll und ganz
          auf die Umsetzung und überlasse mir die lästige Arbeit. Du bist dir
          nicht sicher, ob du das Rezept essen darfst, was du machen sollst,
          wenn dein Gewicht stagniert oder ein Geburtstag ansteht? – Kein
          Problem! Ich bin als dein Coach immer für dich da und übernehme gern
          die Aufgabe.
        </C.Description>
        <C.Action>
          <button
            className="ct-link-text foodiary-green-button cursor-pointer"
            onClick={onPaymentModal}
          >
            Coaching PRO buchen
          </button>
        </C.Action>
      </C.TextContent>
      <C.VisualContent>
        <img src={PersonImage} alt="coach" />
      </C.VisualContent>
    </C.GeneralWrap>
  );
};

export default NutritionCoach;
