import React from "react";
import { useSelector } from "react-redux";
import { PopupButton } from "react-calendly";
import ReactPlayer from "react-player";
import * as C from "./freeInitialConsultation.style";

// import video from './LiveChat.mp4';
import Chip from "components/general/formComponents/chip/Chip";
import LiveCallPng from "assets/images/pngs/Foodiary-Ernahrungsberater.png";
// import LiveCallVideo from "assets/images/new/LiveChat.mp4";

const FreeInitialConsultation = () => {
  const coach = useSelector((state) => state.coach.coach);

  return (
    <C.Wrap>
      <C.ContentSection>
        <Chip text="TERMIN VEREINBAREN" margin="0 0 30px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
          Kostenloses Erstgespräch
        </C.Title>
        <C.Description className="ct-text-block color-paragraph text-base text-left">
          Du kaufst nicht gern die Katze im Sack? Kein Problem! Ich nehme mir
          gern die Zeit für ein unverbindliches und kostenloses Erstgespräch. In
          diesem Video-Meeting entwickeln wir deinen Ernährungsplan und klären
          alle offenen Fragen. Im Anschluss hast du die Wahl, ob du die
          Ernährungsumstellung selbstständig angehen möchtest, oder du mit
          meinem Coaching beginnen möchtest
        </C.Description>
        <C.Action>
          <PopupButton
            className="ct-link-text foodiary-green-button cursor-pointer mt-12"
            url={`${coach?.pp_first_meeting_calendly_url_profilpage}?hide_gdpr_banner=1`}
            rootElement={document.getElementById("root")}
            text="Termin buchen"
          />
        </C.Action>
      </C.ContentSection>
      <C.VisualContent>
        {/* <img src={LiveCallPng} alt="Icon-live-call" /> */}
        <ReactPlayer
          url={
            "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/LiveChat.mp4"
          }
          playsinline
          playing
          muted
          loop
        />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default FreeInitialConsultation;
