import React from "react";
import * as F from "./intelligentRecipeFilter.style";
import Chip from "components/general/formComponents/chip/Chip";

// Images
// import FoodiaryErnahrungsberatung from "assets/images/pngs/Foodiary-Ernahrungsberatung.png";
// import Video from "assets/images/functions/APP/rezepte-auswaehlen-foodiary.mp4";

import ReactPlayer from "react-player";

const IntelligentRecipeFilter = () => {
  return (
    <F.CoachingWithMeContainer>
      <F.ContentSection>
        <Chip text="REZEPTE" margin="0 0 2rem 0" />
        <F.Title className="ct-text-block font-bold color-dark text-left text-4xl">
          Intelligenter Rezeptfilter
        </F.Title>
        <F.Description className="ct-text-block color-paragraph text-base text-left">
          Über 1700 Rezepte warten auf dich! Dabei trifft der Rezeptfilter auf
          Basis deiner Ernährungsform, Kalorien & Makronährwerte eine
          Vorauswahl. So werden dir nur Rezepte angezeigt, welche du wirklich
          ohne Bedenken essen kannst.
        </F.Description>
        <F.Description className="ct-text-block color-paragraph-alt text-base text-left mt-6">
          Dabei bleibst zu 100% flexibel. Aus der Vorauswahl kannst du deine
          Rezepte selbst auswählen, oder den Rezeptfilter kurzzeitig einfach
          deaktivieren.
        </F.Description>
      </F.ContentSection>
      <F.VisualContent>
        {/* <img src={FoodiaryErnahrungsberatung} alt="coach" /> */}
        <ReactPlayer
          url={"https://foodiary.app/data/rezepte-auswaehlen-foodiary.mp4"}
          playsinline
          playing
          muted
          loop
        />
        <div className="white-border" />
      </F.VisualContent>
    </F.CoachingWithMeContainer>
  );
};

export default IntelligentRecipeFilter;
