import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

// Components
import Navbar from "components/Navbar/Navbar";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FooterNew from "components/FooterNew/FooterNew";
import DietMadeEasy from "components/DietMadeEasy/DietMadeEasy";
import SectionWrapper from "components/general/sectionWrapper/SectionWrapper";
import StandardProgram from "components/StandardProgram/StandardProgram";
import NutritionCoach from "components/NutritionCoach/NutritionCoach";
import YoyoEffect from "components/YoyoEffect/YoyoEffect";
import CoachFromFoodiary from "components/CoachFromFoodiary/CoachFromFoodiary";
import BenefitsGlance from "components/BenefitsGlance/BenefitsGlance";
import StartNutritionChange from "components/StartNutritionChange/StartNutritionChange";
import StartTogether from "components/StartTogether/StartTogether";
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";
import PaymentModal from "modals/PaymentModal/PaymentModal";
import FailureDialog from "modals/FailureDialog";
// Redux
import * as CoachActions from "redux/coach/coach.actions";
import * as Routes from "routes/routes";
// Styles
import * as C from "./coachingProApp.styles";
import * as G from "styles/General.Styles";
import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import hook from "assets/images/hook.svg";
import IntelligentRecipeFilter from "components/IntelligentRecipeFilter/IntelligentRecipeFilter";
import CountlessFunctions from "components/CountlessFunctions/CountlessFunctions";
import FunctionsFlow from "components/FunctionsFlow/FunctionsFlow";
import DailyRoutine from "components/DailyRoutine/DailyRoutine";

const CoachingProApp = (props) => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);

  const coach = useSelector((state) => state.coach.coach);

  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const { success } = queryString.parse(search);
    if (success == "true") {
      setSuccessDialog(true);
      history.push(Routes.SUCCESS);
    }
    if (success == "false") {
      setCancelDialog(true);
    }
  }, [search]);

  useEffect(() => {
    window.scrollTo(0, 0);

    // const URL =  "https://felix-fci1.foodiary.app/coaching-pro".split(".app")[0] + ".app";
    const URL = window.location.href.split(".app")[0] + ".app";
    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url: URL },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [dispatch]);

  return (
    <>
      <C.Header>
        <C.HeaderInner>
          <G.ContainerNavbar>
            <StickyNavbar />
          </G.ContainerNavbar>
          <G.Container>
            <C.HeaderContentWrapper>
              <C.HeaderContent>
                <C.Chip>FOODIARY APP</C.Chip>
                <C.HeaderTitle className="ct-text-block font-bold color-dark text-center mt-8 text-5xl">
                  Ernährungsplaner <br /> für deinen Alltag
                </C.HeaderTitle>
                <C.HeaderDescription className="ct-text-block color-paragraph text-base text-center">
                  In meinem Coaching enthalten ist auch die Ernährungsplaner
                  App. Über 1700 Rezepte werden auf dich und deinen Alltag
                  angepasst.
                </C.HeaderDescription>
              </C.HeaderContent>
              <div class="hook-block">
                <img width="100" src={hook} alt="hook" />
              </div>
            </C.HeaderContentWrapper>
          </G.Container>
        </C.HeaderInner>
      </C.Header>
      <G.Container>
        <SectionWrapper margin="12rem 0 0" mobileMargin="10.8rem 0 0">
          <IntelligentRecipeFilter />
        </SectionWrapper>
        <SectionWrapper margin="20.8rem 0 0" mobileMargin="10.8rem 0 0">
          <DailyRoutine />
        </SectionWrapper>
        <SectionWrapper margin="20.8rem 0 0" mobileMargin="10.8rem 0 0">
          <FunctionsFlow />
        </SectionWrapper>
        <SectionWrapper margin="20.8rem 0 0" mobileMargin="10.8rem 0 0">
          <CountlessFunctions />
        </SectionWrapper>
        <PaymentModal
          isOpen={openPaymentModal}
          onClose={() => setOpenPaymentModal(false)}
          couchProfile={coach}
        />
        <FailureDialog
          open={cancelDialog}
          close={() => setCancelDialog(false)}
        />
      </G.Container>
      <SectionWrapper margin="7.8rem 0 0">
        <InitialConsultationBox />
      </SectionWrapper>
      <FooterNew />
    </>
  );
};

export default CoachingProApp;
