import React from 'react';
import * as F from './coachingGlance.styled';
import Chip from "components/general/formComponents/chip/Chip"
import CoachingPlans from "components/CoachingPlans/CoachingPlans"
// Images
import Garantie from "assets/images/myCoaching/icon-garantie.svg"
import ActiveUser from "assets/images/Page3/50k-nutzer.svg"

const CoachingGlance = () => {
  return (
    <F.CoachingGlanceContainer>
      <F.CoachingGlanceLeft>
         <Chip text="PREISE" margin="0 0 30px 0" />
         <F.GlanceTitle className='ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12'>
          Mein Coaching <br/> im Überblick
         </F.GlanceTitle>
         <F.GlanceText className='ct-text-block color-paragraph text-base text-left'>
            Während unserem Erstgespräch erstelle ich deinen eigenen Ernährungsplan und beantworte deine offenen Fragen. Mit dem Foodiary Coaching PRO erhältst du einen persönlichen Ernährungsberater für deine 3-monatige Ernährungsumstellung.
         </F.GlanceText>
         <F.GlanceTags>
          <img src={Garantie} alt="user"/>
          <img src={ActiveUser} alt="user" />
         </F.GlanceTags>
      </F.CoachingGlanceLeft>
      <CoachingPlans />
    </F.CoachingGlanceContainer>
  )
}

export default CoachingGlance