import React from "react";
import * as F from "./countlessFunctions.style";

const IconWithText = ({ item: { icon, title, infoText } }) => {
  return (
    <>
      <div
        id="div_block-117-18"
        class="ct-div-block bg-white shadow-md rounded-xl hover-float flex items-center overflow-hidden p-8"
      >
        <div id="code_block-118-18" class="ct-code-block">
          <img className="function-icon" src={icon} alt={title} />
        </div>
        <div
          id="text_block-119-18"
          class="ct-text-block text-lg color-dark text-center pt-5"
        >
          {title}
        </div>
      </div>
    </>
  );
};

export default IconWithText;
