import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import coachReducer from "redux/coach/coach.reducers";

/*************************************************/

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  coach: coachReducer
});

/*************************************************/

export default persistReducer(persistConfig, rootReducer);

/*************************************************/
