import React from "react";
import { useHistory } from "react-router-dom";
import * as F from "./coachingPlans.styled";
import * as G from "styles/General.Styles";
// Images
import GreenTick from "assets/images/myCoaching/Icon-check.svg";
import CrossIcon from "assets/images/myCoaching/Icon-Cross.svg";
import GreenTickWhiteBg from "assets/images/Page2/Icon-check-green-white.svg";

const CoachingGlance = () => {
  const history = useHistory();
  return (
    <F.CoachingPlansBox className="plansBox">
      <F.PlanBox className="nonActive">
        <F.PlanBoxHead>
          <F.TitleInfo className="ct-text-block uppercase text-base font-normal">
            ERSTGESPRÄCH
          </F.TitleInfo>
          <F.Title className="ct-text-block color-dark text-4xl mb-12 font-medium">
            Kostenlos
          </F.Title>
          <F.SubTitle className="ct-text-block uppercase text-base font-normal">
            einmalig
          </F.SubTitle>
        </F.PlanBoxHead>
        <F.PlanLowerBox>
          <G.PlanBoxIcons>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Erstgespräch
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Unterstützung beim Erstellen deines Ernährungsplanes
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTick} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Erklärung aller Funktionen der Foodiary App
              </G.PlanBoxInformation>
            </li>

            <li>
              <G.PlanBoxIconBox>
                <img src={CrossIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Persönlicher Coach für 3 Monate
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={CrossIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Wöchentliches Fortschritt - Gespräch mit Verbesserungstipps
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={CrossIcon} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Foodiary PRO Funktionen
              </G.PlanBoxInformation>
            </li>
          </G.PlanBoxIcons>
        </F.PlanLowerBox>
        <button
          className="ct-link-text foodiary-outline-button w-full"
          onClick={() => history.push("/erstgespraech")}
        >
          Erstgespräch
        </button>
      </F.PlanBox>

      <F.PlanBox className="active">
        <F.PlanBoxHead>
          <F.TitleInfo className="ct-text-block uppercase text-base font-normal active">
            FOODIARY COACHING PRO
          </F.TitleInfo>
          <F.Title className="ct-text-block color-dark text-4xl mb-12 font-medium">
            150,00 €
          </F.Title>
          <F.SubTitle className="ct-text-block uppercase text-base font-normal">
            pro Monat
          </F.SubTitle>
        </F.PlanBoxHead>
        <F.PlanLowerBox>
          <G.PlanBoxIcons>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Erstgespräch
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Unterstützung beim Erstellen deines Ernährungsplanes
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Erklärung aller Funktionen der Foodiary App
              </G.PlanBoxInformation>
            </li>

            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Persönlicher Coach für 3 Monate
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Wöchentliches Fortschritt - Gespräch mit Verbesserungstipps
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base color-paragraph">
                Foodiary PRO Funktionen
              </G.PlanBoxInformation>
            </li>
          </G.PlanBoxIcons>
        </F.PlanLowerBox>
        <button
          className="ct-link-text foodiary-green-button w-full"
          onClick={() => history.push("/coaching-pro")}
        >
          Coaching PRO
        </button>
      </F.PlanBox>
    </F.CoachingPlansBox>
  );
};

export default CoachingGlance;
