import React from "react"
import Chip from "components/general/formComponents/chip/Chip"

import * as C from "./yoyoEffect.styled"
// Images
import YoyoEffectPng from "assets/images/Page3/Foodiary-Coach-Support-v3.jpg"

const YoyoEffect = () => {
  return (
    <C.Wrap>
      <C.VisualContent>
        <img src={YoyoEffectPng} alt="graph" />
      </C.VisualContent>
      <C.TextContent>
        <Chip text="ERSTGESPRÄCH" margin="0 0 3rem 0" />
        <C.Title className='ct-text-block font-bold color-dark text-left text-4xl'>Der Jojo-Effekt hat keine Chance!</C.Title>
        <C.Description className='ct-text-block color-paragraph text-base text-left'>
          Während deinem 3-monatigen Coaching wirst du nicht nur dein gesetztes
          Ziel erreichen, sondern du lernst auch deine Ernährung dauerhaft
          umzustellen. In der Foodiary Academy bringen wir dir alles über die
          Grundlagen der gesunden Ernährung bei, sodass du nach dem Coaching
          dein Ziel erreicht hast und selbst ein Experte im Bereich der
          Ernährung bist. 
        </C.Description>
        <C.Description className='ct-text-block color-paragraph text-base text-left'>
          So kannst du eigenständig deine zukünftige Ernährung planen und fällst
          nicht auf die fiesen Tricks der Lebensmittelindustrie rein.
        </C.Description>
      </C.TextContent>
    </C.Wrap>
  )
}

export default YoyoEffect
