import React from "react"
import * as C from "./coachFromFoodiary.styled"
import Chip from "components/general/formComponents/chip/Chip"
import CoachingSinglePlan from "components/CoachingSinglePlan/CoachingSinglePlan"
// Images
import Garantie from "assets/images/myCoaching/icon-garantie.svg"
import ActiveUser from "assets/images/Page3/50k-nutzer.svg"

const CoachFromFoodiary = ({onPaymentModal}) => {
  return (
    <C.CoachingGlanceContainer>
      <C.CoachingGlanceLeft>
        <Chip text="PREISE" margin="0 0 3rem 0" />
        <C.GlanceTitle className='ct-text-block font-bold color-dark text-left text-4xl'>Warum ein Coach von Foodiary?</C.GlanceTitle>
        <C.GlanceText className='ct-text-block color-paragraph text-base text-left'>
          Du profitierst von der Erfahrung eines professionellen
          Ernährungscoaches, erreichst dein gesetztes Ziel und veränderst dein
          Leben nachhaltig.
        </C.GlanceText>
        <C.GlanceText className='ct-text-block color-paragraph text-base text-left'>
          Schluss mit dubiosen Diäten- und Ernährungsmythen. Wir kennen uns aus,
          wissen wie man eine Ernährungsumstellung richtig angeht und wir helfen
          dir diese dauerhaft durchzuführen, ohne sich im Alltag einzuschränken.
        </C.GlanceText>
        <C.GlanceTags>
          <img src={Garantie} alt="tag" />
          <img src={ActiveUser} alt="user" />
        </C.GlanceTags>
      </C.CoachingGlanceLeft>
      <C.PlansBox className="singleBox">
        <C.OnePlan>
          <CoachingSinglePlan onPaymentModal={onPaymentModal}/>
        </C.OnePlan>
      </C.PlansBox>
    </C.CoachingGlanceContainer>
  )
}

export default CoachFromFoodiary
