import SimpleIcon1 from "assets/images/functions/APP/FunctionIcons/1.svg";
import SimpleIcon2 from "assets/images/functions/APP/FunctionIcons/2.svg";
import SimpleIcon3 from "assets/images/functions/APP/FunctionIcons/3.svg";
import SimpleIcon4 from "assets/images/functions/APP/FunctionIcons/4.svg";
import SimpleIcon5 from "assets/images/functions/APP/FunctionIcons/5.svg";
import SimpleIcon6 from "assets/images/functions/APP/FunctionIcons/6.svg";
import SimpleIcon7 from "assets/images/functions/APP/FunctionIcons/7.svg";
import SimpleIcon8 from "assets/images/functions/APP/FunctionIcons/8.svg";
import SimpleIcon9 from "assets/images/functions/APP/FunctionIcons/9.svg";
import SimpleIcon10 from "assets/images/functions/APP/FunctionIcons/10.svg";
import SimpleIcon11 from "assets/images/functions/APP/FunctionIcons/11.svg";
import SimpleIcon12 from "assets/images/functions/APP/FunctionIcons/12.svg";
import SimpleIcon13 from "assets/images/functions/APP/FunctionIcons/13.svg";
import SimpleIcon14 from "assets/images/functions/APP/FunctionIcons/14.svg";
import SimpleIcon15 from "assets/images/functions/APP/FunctionIcons/15.svg";
import SimpleIcon16 from "assets/images/functions/APP/FunctionIcons/16.svg";
import SimpleIcon17 from "assets/images/functions/APP/FunctionIcons/17.svg";
import SimpleIcon18 from "assets/images/functions/APP/FunctionIcons/18.svg";
import SimpleIcon19 from "assets/images/functions/APP/FunctionIcons/19.svg";
import SimpleIcon20 from "assets/images/functions/APP/FunctionIcons/20.svg";
import SimpleIcon21 from "assets/images/functions/APP/FunctionIcons/21.svg";
import SimpleIcon22 from "assets/images/functions/APP/FunctionIcons/22.svg";
import SimpleIcon23 from "assets/images/functions/APP/FunctionIcons/23.svg";
import SimpleIcon24 from "assets/images/functions/APP/FunctionIcons/24.svg";
import SimpleIcon25 from "assets/images/functions/APP/FunctionIcons/25.svg";
import SimpleIcon26 from "assets/images/functions/APP/FunctionIcons/26.svg";

import SimpleLightIcon1 from "assets/images/functions/APP/FunctionIcons/light1.svg";
import SimpleLightIcon2 from "assets/images/functions/APP/FunctionIcons/light2.svg";
import SimpleLightIcon3 from "assets/images/functions/APP/FunctionIcons/light3.svg";
import SimpleLightIcon4 from "assets/images/functions/APP/FunctionIcons/light4.svg";

import ProIcon1 from "assets/images/functions/APP/FunctionIcons/PRO/Pro1.svg";
import ProIcon2 from "assets/images/functions/APP/FunctionIcons/PRO/Pro2.svg";
import ProIcon3 from "assets/images/functions/APP/FunctionIcons/PRO/Pro3.svg";
import ProIcon4 from "assets/images/functions/APP/FunctionIcons/PRO/Pro4.svg";
import ProIcon5 from "assets/images/functions/APP/FunctionIcons/PRO/Pro5.svg";
import ProIcon6 from "assets/images/functions/APP/FunctionIcons/PRO/Pro6.svg";
import ProIcon7 from "assets/images/functions/APP/FunctionIcons/PRO/Pro7.svg";
import ProIcon8 from "assets/images/functions/APP/FunctionIcons/PRO/Pro8.svg";
import ProIcon9 from "assets/images/functions/APP/FunctionIcons/PRO/Pro9.svg";
import ProIcon10 from "assets/images/functions/APP/FunctionIcons/PRO/Pro10.svg";
import ProIcon11 from "assets/images/functions/APP/FunctionIcons/PRO/Pro11.svg";
import ProIcon12 from "assets/images/functions/APP/FunctionIcons/PRO/Pro12.svg";
import ProIcon13 from "assets/images/functions/APP/FunctionIcons/PRO/Pro13.svg";
import ProIcon14 from "assets/images/functions/APP/FunctionIcons/PRO/Pro14.svg";
import ProIcon15 from "assets/images/functions/APP/FunctionIcons/PRO/Pro15.svg";
import ProLightIcon1 from "assets/images/functions/APP/FunctionIcons/PRO/ProLight1.svg";

export const SimpleFuntions = [
  {
    icon: SimpleIcon1,
    title: "Kalorienübersicht",
    infoText: "",
  },
  {
    icon: SimpleIcon2,
    title: "Nährstoffverteilung",
    infoText: "",
  },
  {
    icon: SimpleIcon3,
    title: "Dynamische Kalorienanpassung",
    infoText: "",
  },
  {
    icon: SimpleIcon4,
    title: "Detaillierte Tagesinformationen",
    infoText: "",
  },
  {
    icon: SimpleIcon5,
    title: "Tagesplanung",
    infoText: "",
  },
  {
    icon: SimpleIcon6,
    title: "Nährwertanalyse",
    infoText: "",
  },
  {
    icon: SimpleIcon7,
    title: "Zielsetzung festlegen",
    infoText: "",
  },
  {
    icon: SimpleIcon8,
    title: "Aktivitätslevel festlegen",
    infoText: "",
  },
  {
    icon: SimpleIcon9,
    title: "Ernährungsform bestimmen",
    infoText: "",
  },
  {
    icon: SimpleIcon10,
    title: "Tagesabläufe festlegen",
    infoText: "",
  },
  {
    icon: SimpleIcon11,
    title: "Trainingstage festlegen",
    infoText: "",
  },
  {
    icon: SimpleIcon12,
    title: "Individuelles Startdatum",
    infoText: "",
  },
  {
    icon: SimpleIcon13,
    title: "Einkaufsliste",
    infoText: "",
  },
  {
    icon: SimpleIcon14,
    title: "Favoriten",
    infoText: "",
  },
  {
    icon: SimpleIcon15,
    title: "Vegan & Vegetarisch",
    infoText: "",
  },
  {
    icon: SimpleIcon16,
    title: "Unverträglichkeiten",
    infoText: "",
  },
  {
    icon: SimpleIcon17,
    title: "Quick Calories",
    infoText: "",
  },
  {
    icon: SimpleIcon18,
    title: "Lebensmitteldatenbank",
    infoText: "",
  },
  {
    icon: SimpleIcon19,
    title: "Lebensmittel tracken",
    infoText: "",
  },
  {
    icon: SimpleIcon20,
    title: "Lebensmittel anlegen",
    infoText: "",
  },
  {
    icon: SimpleIcon21,
    title: "Rezepte erstellen",
    infoText: "",
  },
  {
    icon: SimpleIcon22,
    title: "Wassertracker",
    infoText: "",
  },
  {
    icon: SimpleIcon23,
    title: "Getränke Datenbank",
    infoText: "",
  },

  {
    icon: SimpleIcon24,
    title: "Getränke tracken",
    infoText: "",
  },
  {
    icon: SimpleIcon25,
    title: "Supplemente Datenbank",
    infoText: "",
  },
  {
    icon: SimpleIcon26,
    title: "Supplemente tracken",
    infoText: "",
  },
];

export const CommingSoonSimpleFunctions = [
  {
    icon: SimpleLightIcon1,
    title: "Getränke anlegen",
    infoText: "Bald verfügbar",
  },
  {
    icon: SimpleLightIcon2,
    title: "Meal Preps anlegen",
    infoText: "Bald verfügbar",
  },
  {
    icon: SimpleLightIcon3,
    title: "Restaurant Gerichte anlegen",
    infoText: "Bald verfügbar",
  },
  {
    icon: SimpleLightIcon4,
    title: "Supplemente anlegen",
    infoText: "Bald verfügbar",
  },
];

export const GreenFunctions = [
  {
    icon: ProIcon1,
    title: "Kalorien bearbeiten",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon2,
    title: "Wochenplanung",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon3,
    title: "Ernährungsform bearbeiten",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon4,
    title: "Tagesabläufe hinzufügen",
    infoText: "",
    isGreenIcon: true,
  },

  {
    icon: ProIcon5,
    title: "Academy",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon6,
    title: "Rezeptdatenbank",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon7,
    title: "Intelligenter Rezeptfilter",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon8,
    title: "Rezept Empfehlungen",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon9,
    title: "Freie Rezeptauswahl",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon10,
    title: "Rezepte tracken",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon11,
    title: "Meal Prep Datenbank",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon12,
    title: "Meal Preps tracken",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon13,
    title: "Restaurant Datenbank",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon14,
    title: "Restaurant Gerichte tracken",
    infoText: "",
    isGreenIcon: true,
  },
  {
    icon: ProIcon15,
    title: "Supplement Empfehlungen",
    infoText: "",
    isGreenIcon: true,
  },
];

export const CommingSoonGreenFunctions = [
  {
    icon: ProLightIcon1,
    title: "Mahlzeitfrequenz festlegen",
    infoText: "Bald verfügbar",
  },
];
