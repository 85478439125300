import React from "react";
import * as F from "./dietMadeEasy.style";
import Chip from "components/general/formComponents/chip/Chip";

// Images
import FoodiaryErnahrungsberatung from "assets/images/pngs/Foodiary-Ernahrungsberatung.png";
// import Video from "assets/images/new/FoodiaryCoaching.mp4"
import ReactPlayer from "react-player";

const DietMadeEasy = ({ onPaymentModal }) => {
  return (
    <F.CoachingWithMeContainer>
      <F.ContentSection>
        <Chip text="Persönliches Coaching" margin="0 0 30px 0" />
        <F.Title className="ct-text-block font-bold color-dark text-left text-4xl">
          Ernährung <br /> leicht gemacht
        </F.Title>
        <F.Description className="ct-text-block color-paragraph text-base text-left">
          Mit dem Ernährungscoaching PRO unterstütze ich dich dabei innerhalb
          der nächsten 3 Monate dein persönliches Ziel zu erreichen. Gemeinsam
          mit dir entwickle ich eine Strategie und einen Ernährungsplan, um
          deine Ernährung dauerhaft umzustellen. Als Foodiary Coach betreue ich
          dich dabei dauerhaft und stehe bei Fragen an deiner Seite.
        </F.Description>
        <F.Action>
          <button
            className="ct-link-text foodiary-green-button cursor-pointer"
            onClick={onPaymentModal}
          >
            Coaching PRO buchen
          </button>
        </F.Action>
      </F.ContentSection>
      <F.VisualContent>
        {/* <img src={FoodiaryErnahrungsberatung} alt="coach" /> */}
        <ReactPlayer
          url={
            "https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/FoodiaryCoaching.mp4"
          }
          playsinline
          playing
          muted
          loop
        />
      </F.VisualContent>
    </F.CoachingWithMeContainer>
  );
};

export default DietMadeEasy;
