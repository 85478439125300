import styled from "styled-components"

export const Container = styled.div`
  /* max-width: 95%;
  margin: auto;

  @media (min-width: 1450px) {
    max-width: 1216px;
  } */

  /* @media (min-width: 992px) {
    max-width: 85%;
  }

  @media (min-width: 1200px) {
    max-width: 80%;
  } */
`

export const NavbarWrapper = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  background-color: transparent;
  z-index: 9998;
  transition: all 0.34s ease;
  position: fixed;
  top: 3rem;
  /* padding: 16px 16px 16px 25.6px; */
  /* padding: 1.4rem 1rem 1.4rem 1.6rem; */
  box-sizing: border-box;
  border-radius: 20px;
  max-width: 95%;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1150px) {
    &:hover {
      box-shadow: 0px 5px 10px #142c1f14;
      background: white;
    }
  }

  margin: auto;

  @media (min-width: 1450px) {
    max-width: 1216px;
  }

  @media (min-width: 1800px) {
    max-width: 1503px;
  }

  /* @media (min-width: 1200px) {
    max-width: 80%;
  } */

  /* @media (max-width: 767.99px) {
    width: calc(100% - 30px);
    left: 15px;
  } */

  @media (max-width: 600px) {
    padding: 16px 14px 16px 22.4px;
  }

  ${({ isSticky }) =>
    isSticky &&
    `
    box-shadow: 0px 5px 10px #142C1F14;
    background: white;
  `}

  ${({ showMobileMenu }) =>
    showMobileMenu &&
    `
    // box-shadow: 0px 2px 10px #142c1f14;
    background: white;
    box-shadow: 0px 4000px 500px 4000px rgba(0, 0, 0, 0.13);
  `}
`

export const Navbar = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  z-index: 9998;
  transition: all 0.34s ease;
  /* position: fixed;
  top: 30px; */
  /* padding: 20px 16px 20px 25.6px; */
  box-sizing: border-box;
  /* border-radius: 20px;
  max-width: 95%; */
  width: 100%;

  /* &:hover {
    box-shadow: 0px 5px 10px #142c1f14;
    background: white;
  }

  @media (min-width: 992px) {
    max-width: 88%;
  } */

  /* @media (min-width: 1200px) {
    max-width: 80%;
  } */

  /* @media (max-width: 767.99px) {
    width: calc(100% - 30px);
    left: 15px;
  } */

  /* @media (max-width: 600px) {
    padding: 15px 25.6px 15px 25.6px;
  } */

  /* ${({ isSticky }) =>
    isSticky &&
    `
    box-shadow: 0px 5px 10px #142C1F14;
    background: white;
  `}

  ${({ showMobileMenu }) =>
    showMobileMenu &&
    `
    border-radius: 20px 20px 0 0;
    // box-shadow: 0px 2px 10px #142c1f14;
    background: white;
    box-shadow: 0px 4000px 500px 4000px rgba(0, 0, 0, 0.2);
  `} */
`

export const Logo = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 19.5rem;
  margin-left: -0.7rem;
  /* height: 60px; */

  @media (max-width: 600px) {
    width: 15rem;
    /* height: 3.2rem; */
  }
`

// export const Links = styled.ul`
//   list-style: none;
//   margin: 0 auto;
//   padding: 0;
//   width: fit-content;

//   li {
//     padding: 1rem 1.5rem;
//     display: inline-block;
//     a {
//       color: white;
//       text-decoration: none;
//       font-weight: 600;
//       font-size: 20px;
//     }
//   }

//   @media (max-width: 600px) {
//     display: none;
//   }
// `;

export const NavItems = styled.ul.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin: 0 0 0 auto;
  padding: 0;
  width: fit-content;
  gap: 32px;

  li {
    .router-nav-link {
      transition: all ease-in-out 0.3s;
      z-index: 9999;
      position: relative;
      /* font-weight: 400;
      font-size: 1rem; */
      cursor: pointer;
      /* margin: 1rem 1.3rem 0; */
      color: #44554c;
      text-decoration: none;

      ::after {
        content: "";
        height: 0.3rem;
        width: 3rem;
        margin-inline: auto;
        border-radius: 0.13rem;
        position: absolute;
        bottom: -0.9rem;
        left: calc(50% - 0rem);
        transition: all ease-in-out 0.3s;
        translate: -50% 0;
      }

      &:hover::after {
        background-color: #a5b5af;
      }

      &.active {
        color: #142c1f;
        &::after {
          background-color: #2dcc95;
        }
      }
    }
  }

  @media (max-width: 1150px) {
    display: none;
  }
`

export const MobileNavItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
  padding-left: 0;

  img {
    width: 4rem;
    height: 4rem;
    cursor: pointer;
  }

  .menu-icon {
    width: 3.3rem;
    cursor: pointer;
  }

  .cross-icon {
    width: 1.5rem;
    cursor: pointer;
  }

  @media (min-width: 1149.99px) {
    display: none;
  }

  @media (max-width: 767.99px) {
    button {
      padding: 0.8rem 1rem;
    }
  }
`

export const MobileNavbar = styled.div`
  /* position: fixed;
  top: 20px; */
  /* width: calc(100% - 10px); */
  /* box-shadow: 0px 5px 10px #142c1f14; */
  /* transition: all 0.34s ease; */
  background: white;
  z-index: 9991;
  /* border-radius: 0 0 20px 20px; */
  /* position: fixed; */
  padding: 1.6rem;
  /* box-shadow: 0 -1px 9px rgb(20 45 82 / 3%), 0 0 1px rgb(20 45 82 / 1%),
    0 1px 1px rgb(20 45 82 / 1%), 0 1px 2px rgb(20 45 82 / 2%),
    0 3px 4px rgb(20 45 82 / 3%), 0 5px 8px rgb(20 45 82 / 4%),
    0 9px 14px rgb(20 45 82 / 5%); */
  /* box-shadow: 0px 6px 9px rgb(20 45 82 / 3%), 0px 8px 1px rgb(20 45 82 / 1%),
    0 1px 1px rgb(20 45 82 / 1%), 0 1px 2px rgb(20 45 82 / 2%),
    0 3px 4px rgb(20 45 82 / 3%), 0 5px 8px rgb(20 45 82 / 4%),
    0 9px 14px rgb(20 45 82 / 5%); */
  /* box-shadow: 0px 4000px 500px 4000px rgba(0, 0, 0, 0.2); */

  /* max-width: 95%; */
  width: 100%;
  box-sizing: border-box;
  /* top: 126px; */
  margin-inline: auto;
  @media (min-width: 992px) {
    max-width: 85%;
  }

  @media (min-width: 1200px) {
    max-width: 80%;
  }

  @media (max-width: 600px) {
    /* height: calc(100vh - 40px); */
  }
`

export const NavbarItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.7rem;
  @media (max-width: 600px) {
    /* height: calc(100vh - 300px); */
  }
`

export const MobileNavbarItems = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 2rem;
    .router-nav-link {
      padding: 0;
      /* font-family: "RubikRegular"; */
      color: #44554c;
      cursor: pointer;
      /* font-size: 1.1rem;
      font-weight: 400; */
      text-decoration: none;
      display: block;
      position: relative;

      &.active {
        &::after {
          content: "";
          height: 0.3rem;
          width: 3rem;
          margin-inline: auto;
          background-color: #2dcc95;
          border-radius: 0.13rem;
          position: absolute;
          bottom: -0.9rem;
          left: 0;
          transition: all ease-in-out 0.3s;
        }
      }
    }
  }
`

export const Header = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  width: 100%;
  margin: 1rem 0 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MobileNavbarLogo = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 8rem;
  margin-left: -0.9rem;
`

export const Close = styled.img.attrs((props) => ({
  src: props.src,
}))`
  width: 1.9rem;
  height: 1.9rem;
  cursor: pointer;
`

export const Divider = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  width: 100%;
  background: #dddddd;
  margin: 1rem 0 2rem;
  height: 1px;
`

export const AppDownloadItem = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  font-size: 2rem;
  font-weight: 500;
  color: #1c9d87;
  margin-bottom: 1rem;
  /* font-family: "RubikRegular"; */
  display: flex;
  align-items: center;
  gap: 1rem;
  img {
    width: 2rem;
    height: 2.3rem;
  }
`

export const LoginItem = styled.p.attrs({
  className: `${(props) => props.className}`,
})`
  font-size: 2rem;
  font-weight: 500;
  /* font-family: "RubikRegular"; */
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 2rem;
    height: 2rem;
  }
`
