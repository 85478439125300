import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Routes
import * as routes from "routes/routes";
import CustomRoute from "routes/customRoute";

// Pages
import Login from "pages/login/Login";
import MyCoaching from "pages/MyCoaching/MyCoaching";
import CoachingProCoaching from "pages/CoachingProCoaching/CoachingProCoaching";
import CoachingProApp from "pages/CoachingProApp/CoachingProApp";
import InitialConsultation from "pages/InitialConsultation/InitialConsultation";
import CoachingPro from "pages/CoachingPro/CoachingPro";
import Success from "pages/Success/Success";

function App() {
  return (
    <Router>
      <Switch>
        <CustomRoute path={routes.LOGIN} exact component={Login} />
        <CustomRoute path={routes.MY_COACHING} exact component={MyCoaching} />
        <CustomRoute
          path={routes.INITIAL_CONSULTATION}
          exact
          component={InitialConsultation}
        />
        <CustomRoute path={routes.COACHING_PRO} exact component={CoachingPro} />
        <CustomRoute
          path={routes.COACHING_PRO_APP}
          exact
          component={CoachingProApp}
        />
        <CustomRoute
          path={routes.COACHING_PRO_COACHING}
          exact
          component={CoachingProCoaching}
        />
        <CustomRoute path={routes.SUCCESS} exact component={Success} />
        {/* <CustomRoute
            restricted
            path={routes.ROOT}
            exact
            component={<div>Root</div>}
          /> */}
        <Route
          exact
          path="/"
          render={() => <Redirect to={routes.MY_COACHING} />}
        />
        <Route path="*" render={() => "Route Not Found"} />
      </Switch>
    </Router>
  );
}

export default App;
