import { createGlobalStyle } from "styled-components"
import RubikRegular from "assets/fonts/Rubik-Regular.ttf"
import RubikMedium from "assets/fonts/Rubik-Medium.ttf"
import RubikBold from "assets/fonts/Rubik-Bold.ttf"
import RubikLight from "assets/fonts/Rubik-Light.ttf"
import ExoRegular from "assets/fonts/Exo-Regular.ttf"
import ExoSemiBold from "assets/fonts/Exo-SemiBold.ttf"
import AdoreYou from "assets/fonts/FontsFree-adore-you.ttf"

const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'RubikRegular';
        src: url(${RubikRegular}) format('truetype')
    }
    @font-face {
        font-family: 'RubikMedium';
        src: url(${RubikMedium}) format('truetype')
    }
    @font-face {
        font-family: 'RubikLight';
        src: url(${RubikLight}) format('truetype')
    }
    @font-face {
        font-family: 'RubikBold';
        src: url(${RubikBold}) format('truetype')
    }
    @font-face {
        font-family: 'ExoRegular';
        src: url(${ExoRegular}) format('truetype')
    }
    @font-face {
        font-family: 'ExoSemiBold';
        src: url(${ExoSemiBold}) format('truetype')
    }

    @font-face {
        font-family: 'AdoreYou';
        src: url(${AdoreYou}) format('truetype')
    }
   

    body {
        margin: 0;
        padding: 0;
        background: #FDFFFE
        /* font-family: RubikLight, Georgia; */
       /* font-family: RubikMedium, Georgia; */
    }

    * {
        box-sizing: border-box;
        word-break: break-word;
    }

    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
    }

    p {
        line-height: 1.5;
    }
    /* @keyframes progress {
        0% {
            stroke-dasharray: 0 100;
        }
    }

    @keyframes toast-in-right {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes toast-in-left {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    } */

    /* .input {
        border: rgba(165,181,175,.15) 2px solid;
    }

    .TimePicker-header-4, .Clock-innerDot-17, .Clock-pointer-14 {
        background-color: #1C9D87 !important;
    }

    .Clock-outerDot-18 {
        border: 16px solid #1C9D87 !important;
    }

    .MuiButton-textPrimary {
        color: #1C9D87 !important;
    }

    .react-multi-carousel-list {
        position: inherit;
    }

    .carousel-item-padding-30-px {
        margin-left: 30px;
    }

    .health-score-tips-carousel {
        margin-left: -10px;
    }
    
    .premium-coaching-success-carousel {
        margin-left: 100px;
    } */
    
    .singleBox .nonActive{
        display:none;
    }
    .singleBox .active button{
        /* font-size:19px !important; */
    }
     .singleBox .plansBox{
        grid-template-columns: 1fr;
    }
`

export default GlobalStyles
