import React from "react";
import * as F from "./countlessFunctions.style";

const LightGreenIconWithText = ({ item: { icon, title, infoText } }) => {
  return (
    <>
      <div
        id="div_block-400-18"
        class="ct-div-block bg-white shadow-md rounded-xl hover-float flex items-center overflow-hidden p-8 relative"
      >
        <div id="code_block-401-18" class="ct-code-block">
          <img className="function-icon" src={icon} alt={title} />
        </div>
        <F.ProBadge>PRO</F.ProBadge>
        <span className="function-info-text">{infoText}</span>
        <div
          id="text_block-403-18"
          class="ct-text-block text-lg text-center pt-5 color-paragraph-alt"
        >
          {title}
        </div>
      </div>
    </>
  );
};

export default LightGreenIconWithText;
