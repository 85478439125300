import React from "react";
import Button from "components/general/formComponents/button/Button";
import * as F from "./coachingSinglePlan.styled";
import * as G from "styles/General.Styles";
// Images
import GreenTickWhiteBg from "assets/images/Page2/Icon-check-green-white.svg";

const CoachingSinglePlan = ({ onPaymentModal }) => {
  return (
    <F.CoachingPlansBox className="plansBox">
      <F.PlanBox className="active">
        <F.PlanBoxHead>
          <F.TitleInfo className="active ct-text-block uppercase text-base font-normal">
            FOODIARY COACHING PRO
          </F.TitleInfo>
          <F.Title className="ct-text-block color-dark text-4xl mb-12 font-medium">
            150,00 €
          </F.Title>
          <F.SubTitle className="ct-text-block uppercase text-base font-normal">
            pro Monat
          </F.SubTitle>
        </F.PlanBoxHead>
        <F.PlanLowerBox>
          <G.PlanBoxIcons>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base">
                Erstgespräch
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base">
                Unterstützung beim Erstellen deines Ernährungsplanes
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base">
                Erklärung aller Funktionen der Foodiary App
              </G.PlanBoxInformation>
            </li>

            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base">
                Persönlicher Coach für 3 Monate
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base">
                Wöchentliches Fortschritt - Gespräch mit Verbesserungstipps
              </G.PlanBoxInformation>
            </li>
            <li>
              <G.PlanBoxIconBox>
                <img src={GreenTickWhiteBg} alt="Tick" />
              </G.PlanBoxIconBox>
              <G.PlanBoxInformation className="ct-text-block text-base">
                Foodiary PRO Funktionen
              </G.PlanBoxInformation>
            </li>
          </G.PlanBoxIcons>
        </F.PlanLowerBox>
        <button
          className="ct-link-text foodiary-green-button cursor-pointer w-full"
          onClick={onPaymentModal}
        >
          Coaching PRO buchen
        </button>
      </F.PlanBox>
    </F.CoachingPlansBox>
  );
};

export default CoachingSinglePlan;
